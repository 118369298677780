import React, { useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import { InputMask, InputMaskChangeEvent } from "primereact/inputmask";
import { Calendar } from "primereact/calendar";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Button } from "primereact/button";
import {
  url_register,
  url_patient,
  url_patient_verify,
  url_patient_info,
  url_checkUser,
  url_checkPatient,
  url_updateEmail,
} from "../lib/url";
import "./Registration.css";
import { useSelector } from "react-redux";
import { RootState } from "../Redux/store";

const generi = [
  { name: "Maschio", code: true },
  { name: "Femmina", code: false },
  { name: "Altro", code: null },
];

const Registration: React.FC<{ setActiveIndex: (index: number) => void }> = ({ setActiveIndex }) => {
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [codiceFiscale, setCodiceFiscaleInput] = useState<string>("");
  const [nome, setNome] = useState<string>("");
  const [cognome, setCognome] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [telefono, setTelefono] = useState<string>("");
  const [birthdate, setBirthdate] = useState<Date | null>(null);
  const [genere, setGenere] = useState<boolean | null>(null);
  const [passwordError, setPasswordError] = useState<string>(""); 
  const [fieldErrors, setFieldErrors] = useState<any>({});
  const toast = useRef<Toast>(null);
  const token = useSelector((state: RootState) => state.user.token);

  const handleTelefonoChange = (e: InputMaskChangeEvent) => {
    setTelefono(e.value ? e.value.toString() : "");
  };

  const handleCodiceFiscaleChange = (e: InputMaskChangeEvent) => {
    setCodiceFiscaleInput(e.value ? e.value.toString() : "");
  };

  const validatePassword = (password: string) => {
    const regex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return regex.test(password);
  };

  const handlePasswordBlur = () => {
    if (!validatePassword(password)) {
      setPasswordError("La password deve essere lunga almeno 8 caratteri, contenere lettere, numeri e un simbolo speciale.");
    } else {
      setPasswordError(""); 
    }
  };

  const validateFields = () => {
    const errors: any = {};
    if (!nome) errors.nome = "Nome è obbligatorio.";
    if (!cognome) errors.cognome = "Cognome è obbligatorio.";
    if (!email) errors.email = "Email è obbligatoria.";
    if (!telefono) errors.telefono = "Telefono è obbligatorio.";
    if (!codiceFiscale) errors.codiceFiscale = "Codice Fiscale è obbligatorio.";
    if (!birthdate) errors.birthdate = "Data di nascita è obbligatoria.";
    if (!genere) errors.genere = "Genere è obbligatorio.";
    if (!password) errors.password = "Password è obbligatoria.";
    if (password !== confirmPassword) errors.confirmPassword = "Le password non coincidono.";
    return errors;
  };

  const postRegister = async (bodyReg: any, patientBody: any, codiceFiscale: string, verifyBody: any) => {
    try {
		const response = await fetch(url_register, { // Usa la variabile importata
		  method: "POST",
		  body: JSON.stringify(bodyReg),
		  headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		  },
		});

      if (response.ok) {
        toast.current?.show({
          severity: "success",
          summary: "Registrazione completata",
          detail: "Clicca il link ricevuto per email per validare l'account e poi fare il login.",
          life: 10000,
        });
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Registrazione Fallita",
          detail: "Errore durante la registrazione.",
          life: 5000,
        });
      }
    } catch (error) {
      toast.current?.show({
        severity: "error",
        summary: "Errore",
        detail: "C'è stato un errore, riprova tra un po'.",
        life: 3000,
      });
    }
  };

  const handleRegisterSubmit = () => {
    const errors = validateFields();
    setFieldErrors(errors);

    if (Object.keys(errors).length > 0) {
      toast.current?.show({
        severity: "error",
        summary: "Registrazione Fallita",
        detail: "Per favore, compila tutti i campi.",
        life: 3000,
      });
      return;
    }

    const regBody = {
      codiceFiscale: codiceFiscale,
      email: email,
      password: password,
    };
    const verifyBody = {
      mail: email,
      fiscalCode: codiceFiscale,
    };
    const patientBody = {
      firstName: nome,
      lastName: cognome,
      fiscalCode: codiceFiscale,
      gender: genere,
      mail: email,
      birthDate: birthdate,
    };

    postRegister(regBody, patientBody, codiceFiscale, verifyBody);
  };

  return (
    <div className="registration-div">
      <Toast ref={toast} position="center" />
      <div className="registration-form">
        <h2>Registrazione</h2>
        <div className={`registration-input ${fieldErrors.cognome ? 'error-field' : ''}`}>
          <FloatLabel>
            <InputText
              id="cognome"
              value={cognome}
              onChange={e => setCognome(e.target.value)}
              required
            />
            <label>Cognome</label>
          </FloatLabel>
          {fieldErrors.cognome && <small className="p-error">{fieldErrors.cognome}</small>}
        </div>
        <div className={`registration-input ${fieldErrors.nome ? 'error-field' : ''}`}>
          <FloatLabel>
            <InputText
              id="nome"
              value={nome}
              onChange={e => setNome(e.target.value)}
              required
            />
            <label>Nome</label>
          </FloatLabel>
          {fieldErrors.nome && <small className="p-error">{fieldErrors.nome}</small>}
        </div>
        <div className={`registration-input ${fieldErrors.email ? 'error-field' : ''}`}>
          <FloatLabel>
            <InputText
              id="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
            <label>Email</label>
          </FloatLabel>
          {fieldErrors.email && <small className="p-error">{fieldErrors.email}</small>}
        </div>
        <div className={`registration-input ${fieldErrors.telefono ? 'error-field' : ''}`}>
          <FloatLabel>
            <InputMask
              id="telefono"
              value={telefono}
              onChange={handleTelefonoChange}
              mask="9999999999"
              required
            />
            <label>Telefono</label>
          </FloatLabel>
          {fieldErrors.telefono && <small className="p-error">{fieldErrors.telefono}</small>}
        </div>
        <div className={`registration-input ${fieldErrors.codiceFiscale ? 'error-field' : ''}`}>
          <FloatLabel>
            <InputMask
              id="codiceFiscale"
              value={codiceFiscale}
              onChange={handleCodiceFiscaleChange}
              mask="aaaaaa99a99a999a"
              required
            />
            <label>Codice Fiscale</label>
          </FloatLabel>
          {fieldErrors.codiceFiscale && <small className="p-error">{fieldErrors.codiceFiscale}</small>}
        </div>
        <div className={`registration-input ${fieldErrors.birthdate ? 'error-field' : ''}`}>
          <FloatLabel>
            <Calendar
              id="birthdate"
              value={birthdate}
              onChange={e => setBirthdate(e.value as Date)}
              showIcon
              dateFormat="dd/mm/yy"
              required
            />
            <label>Data di Nascita</label>
          </FloatLabel>
          {fieldErrors.birthdate && <small className="p-error">{fieldErrors.birthdate}</small>}
        </div>
        <div className={`registration-input ${fieldErrors.genere ? 'error-field' : ''}`} style={{ width: "100%" }}>
          <label htmlFor="genere">Genere</label>
          <Dropdown
            id="genere"
            value={genere}
            options={generi}
            onChange={e => setGenere(e.value)}
            optionLabel="name"
            optionValue="code"
            placeholder="Seleziona un genere"
            required
          />
          {fieldErrors.genere && <small className="p-error">{fieldErrors.genere}</small>}
        </div>
        <div className={`registration-input password-container ${fieldErrors.password ? 'error-field' : ''}`}>
          <FloatLabel>
            <Password
              id="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              onBlur={handlePasswordBlur}
              toggleMask
              feedback={false}
              required
            />
            <label>Password</label>
          </FloatLabel>
          {passwordError && <small className="p-error">{passwordError}</small>}
          {fieldErrors.password && <small className="p-error">{fieldErrors.password}</small>}
        </div>
        <div className={`registration-input password-container ${fieldErrors.confirmPassword ? 'error-field' : ''}`}>
          <FloatLabel>
            <Password
              id="confirmPassword"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              toggleMask
              feedback={false}
              required
            />
            <label>Conferma Password</label>
          </FloatLabel>
          {fieldErrors.confirmPassword && <small className="p-error">{fieldErrors.confirmPassword}</small>}
        </div>
        <div className="card flex flex-wrap justify-content-center gap-3">
          <Button className="submit-btn" label="Registrati" icon="pi pi-check" onClick={handleRegisterSubmit} />
        </div>
      </div>
    </div>
  );
};

export default Registration;
