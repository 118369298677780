import React, { useEffect, useState } from "react";
import { TabMenu } from "primereact/tabmenu";
import { MenuItem } from "primereact/menuitem";
import Prenotazioni from "./Prenotazioni";
import Login from "./Login";
import Appuntamenti from "./Appuntamenti";
import "./MainPage.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../Redux/store";
import { url_getActivities, url_patient_info, url_token } from "../lib/url";
import { setPatientInfo, setToken } from "../Redux/reducer/userSlice";
import Registration from "./Registration";
import { popHistory, pushHistory } from "../Redux/reducer/historySlice";
import { Activity, GroupedActivity } from "../lib/types";

const MainPage: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [groupedActivities, setGroupedActivities] = useState<GroupedActivity[]>([]);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch<AppDispatch>();
  const token = useSelector((state: RootState) => state.user.token);
  const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);
  const codiceFiscale = useSelector((state: RootState) => state.user.codiceFiscale);
  const history = useSelector((state: RootState) => state.history.history);
  const items: MenuItem[] = [
    { label: "Prenotazioni", icon: "pi pi-fw pi-calendar" },
    { label: "Appuntamenti", icon: "pi pi-fw pi-list-check" },
    { label: isLoggedIn ? "Account" : "Login", icon: isLoggedIn ? "pi pi-fw pi-user" : "pi pi-fw pi-sign-in" },
    ...(isLoggedIn ? [] : [{ label: "Registrazione", icon: "pi pi-fw pi-user-plus" }]),
  ];
  const clientId = "client";
  const tokenSecret = "754fee8a10713ae8c335434a5f859ea1b00d17ee750d0c51957e9463d7776d0f";

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await fetch(url_token, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ClientId: clientId,
            ClientSecret: tokenSecret,
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        dispatch(setToken(data.accessToken));
      } catch (error) {
        if (error instanceof Error) {
          console.error("Fetch error:", error.message);
        } else {
          console.error("Unexpected error:", error);
        }
      }
    };

    fetchToken();
  }, [dispatch]);
  useEffect(() => {
    const fetchPatientInfo = async () => {
      if (isLoggedIn && codiceFiscale) {
        try {
          const response = await fetch(`${url_patient_info}${codiceFiscale}`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();

          dispatch(setPatientInfo(data));
        } catch (error) {
          if (error instanceof Error) {
            console.error("Fetch error:", error.message);
          } else {
            console.error("Unexpected error:", error);
          }
        }
      }
    };

    fetchPatientInfo();
  }, [isLoggedIn, codiceFiscale, dispatch]);
  useEffect(() => {
    const fetchActivities = async () => {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 8000);

      try {
        setLoading(true);
        const response = await fetch(url_getActivities, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          signal: controller.signal,
        });

        clearTimeout(timeoutId);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data: { result: string; msg: string; return: Activity[] } = await response.json();
        setActivities(data.return);
        const grouped: { [key: string]: GroupedActivity } = {};
        data.return.forEach(activity => {
          if (!grouped[activity.group]) {
            grouped[activity.group] = { label: activity.group, items: [] };
          }
          grouped[activity.group].items.push(activity);
        });

        const groupedArray = Object.values(grouped);
        setGroupedActivities(groupedArray);
        setLoading(false);
      } catch (error) {
        if (error instanceof Error) {
          if (error.name === "AbortError") {
            console.error("abort error");
          } else {
            console.error("Fetch error:", error.message);
          }
        } else {
          console.error("Unexpected error:", error);
        }
      }
    };

    fetchActivities();
  }, [token]);

  const handleTabChange = (index: number) => {
    dispatch(pushHistory(activeIndex));
    setActiveIndex(index);
  };

  const handleBack = () => {
    if (history.length > 0) {
      const previousIndex = history[history.length - 1];
      dispatch(popHistory());
      setActiveIndex(previousIndex);
    }
  };

  return (
    <div className="tabmenu-div">
      <TabMenu
        model={items}
        className="tabmenu"
        activeIndex={activeIndex}
        onTabChange={e => handleTabChange(e.index)}
      />
      <i className="pi pi-arrow-left back-icon" onClick={handleBack} style={{ cursor: "pointer", margin: "10px" }}></i>
      <div className="content">
        {activeIndex === 0 && (
          <Prenotazioni
            setActiveIndex={setActiveIndex}
            loading={loading}
            activities={activities}
            groupedActivities={groupedActivities}
          />
        )}
        {activeIndex === 1 && (
          <Appuntamenti
            setActiveIndex={setActiveIndex}
            activities={activities}
            groupedActivities={groupedActivities}
            loading={loading}
          />
        )}
        {activeIndex === 2 && <Login setActiveIndex={setActiveIndex} />}
        {!isLoggedIn && activeIndex === 3 && <Registration setActiveIndex={setActiveIndex} />}
      </div>
    </div>
  );
};

export default MainPage;
