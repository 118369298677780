import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface HistoryState {
  history: number[];
}

const initialState: HistoryState = {
  history: [],
};

const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    pushHistory(state, action: PayloadAction<number>) {
      state.history.push(action.payload);
    },
    popHistory(state) {
      state.history.pop();
    },
  },
});

export const { pushHistory, popHistory } = historySlice.actions;
export default historySlice.reducer;
