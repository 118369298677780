import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import "./Prenotazioni.css";
import { Nullable } from "primereact/ts-helpers";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { addLocale } from "primereact/api";
import { InputSwitch, InputSwitchChangeEvent } from "primereact/inputswitch";
import { url_getResources, url_searchAvailabilities, url_addAppointment, insurance, location } from "../lib/url";
import { Toast } from "primereact/toast";
import { RootState, AppDispatch } from "../Redux/store/index";
import { setAppointment } from "../Redux/reducer/userSlice";
import { Activity, GroupedActivity } from "../lib/types";
import { Dialog } from "primereact/dialog";
import { Accordion, AccordionTab } from "primereact/accordion";

interface Options {
  label: string;
  value: string;
}

interface Resource {
  id: string;
  locationId: string;
  resourceId: string;
  name: string;
  surname: string;
}

interface Availability {
  id: string;
  date: string;
  startTime: string;
  endTime: string;
  resourceId: string;
  activityId: string;
  insuranceId: string;
  locationId: string;
  price: number;
}

interface PrenotazioniProps {
  setActiveIndex: (index: number) => void;
  activities: Activity[];
  groupedActivities: GroupedActivity[];
  loading: boolean;
}

addLocale("it", {
  firstDayOfWeek: 1,
  dayNames: ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
  dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
  dayNamesMin: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
  monthNames: [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ],
  monthNamesShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
  today: "Oggi",
  clear: "Pulisci",
});

const Prenotazioni: React.FC<PrenotazioniProps> = ({ setActiveIndex, activities, groupedActivities, loading }) => {
  const [medici, setMedici] = useState<{ label: string; value: string }[]>([]);
  const [selectPrestazione, setSelectPrestazione] = useState<Activity | null>(null);
  const [selectMedico, setSelectMedico] = useState<string | null>(null);
  const [date, setDate] = useState<Nullable<Date>>(null);
  const [startTime, setStartTime] = useState<string | null>("00:01");
  const [endTime, setEndTime] = useState<string | null>("23:59");
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState<Availability | null>(null);
  const [checked, setChecked] = useState<boolean>(false);
  const [medDis, setMedDis] = useState<boolean>(false);
  const [availabilities, setAvailabilities] = useState<Availability[]>([]);
  const today = new Date();
  const toast = useRef<Toast>(null);
  const dispatch = useDispatch<AppDispatch>();
  const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);
  const user = useSelector((state: RootState) => state.user);
  const token = useSelector((state: RootState) => state.user.token);
  const openDialog = (availability: Availability) => {
    setSelectedAppointment(availability);
    setDialogVisible(true);
  };

  const groupByDate = (availabilities: Availability[]) => {
    return availabilities.reduce((groups, availability) => {
      const date = availability.date;
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(availability);
      return groups;
    }, {} as { [key: string]: Availability[] });
  };

  const confirmAppointment = () => {
    if (selectedAppointment) {
      handlePrenotaClick(selectedAppointment);
    }
    setDialogVisible(false);
  };
  const generateTimeOptions = (start: string, end: string, interval: number) => {
    const times = [];
    let currentTime = new Date(`1970-01-01T${start}:00`);
    const endTime = new Date(`1970-01-01T${end}:00`);

    while (currentTime <= endTime) {
      const hours = currentTime.getHours().toString().padStart(2, "0");
      const minutes = currentTime.getMinutes().toString().padStart(2, "0");
      times.push({ label: `${hours}:${minutes}`, value: `${hours}:${minutes}` });
      currentTime = new Date(currentTime.getTime() + interval * 60000);
    }

    return times;
  };

  const timeOptions = generateTimeOptions("07:00", "21:00", 15);

  const groupedItemTemplate = (option: Options) => {
    return (
      <div className="flex align-items-center">
        <div>{option.label}</div>
      </div>
    );
  };

  const formatDate = (date: Date): string => {
    const pad = (n: number) => (n < 10 ? `0${n}` : n);
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
  };

  const handleSubmit = async () => {
    if (!selectPrestazione) {
      toast.current?.show({
        severity: "error",
        summary: "Errore",
        detail: "Seleziona una prestazione.",
        life: 3000,
      });
      return;
    }

    const prestazioneId = selectPrestazione.id;

    const searchParams = new URLSearchParams({
      activity_lid: prestazioneId,
      ava_results_number: "100",
      resource_lid: selectMedico ? String(selectMedico) : "",
      ava_min_time: startTime ? `${startTime}:00` : "00:01:00",
      ava_max_time: endTime ? `${endTime}:00` : "23:59:00",
      ava_start_time: "00:01:00",
      ava_end_time: "23:59:00",
      insurance_lid: insurance,
    });

	// Migliorata la gestione delle date per evitare strani comportamenti dell'endDate
	// Funzione per aggiungere mesi in modo sicuro con tipi in TypeScript
	const addMonths = (date: Date, months: number): Date => {
	  const originalDay = date.getDate();
	  date.setMonth(date.getMonth() + months);

	  // Correggi se il mese non ha abbastanza giorni
	  if (date.getDate() !== originalDay) {
		date.setDate(0); // Imposta al massimo giorno del mese precedente
	  }

	  return date;
	};

	// Imposta la data di inizio e la data di fine
	const startDate = date ? new Date(date) : new Date(); // Usa la data corrente o quella selezionata
	console.log("Data di inizio:", startDate); // Debug

	// Aggiungi 3 mesi alla data di inizio
	const endDate = addMonths(new Date(startDate), 3);
	console.log("Data di fine:", endDate); // Debug

	// Aggiungi i parametri di ricerca all'URL
	searchParams.append("ava_start_day", formatDate(startDate));
	searchParams.append("ava_end_day", formatDate(endDate));

    try {
      const response = await fetch(`${url_searchAvailabilities}?${searchParams.toString()}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.result === "KO") {
        throw new Error(data.msg);
      }

      if (data.msg === "No availability found.") {
        toast.current?.show({
          severity: "warn",
          summary: "Nessuna disponibilità",
          detail: "Non ci sono disponibilità per i criteri selezionati.",
          life: 3000,
        });
        setAvailabilities([]);
      } else {
        setAvailabilities(data.return);
        toast.current?.show({
          severity: "info",
          summary: "Successo",
          detail: "Disponibilità caricate con successo.",
          life: 3000,
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error("Fetch error:", error.message);
        toast.current?.show({
          severity: "error",
          summary: "Errore",
          detail: `Si è verificato un errore: ${error.message}`,
          life: 3000,
        });
      } else {
        console.error("Unexpected error:", error);
        toast.current?.show({
          severity: "error",
          summary: "Errore",
          detail: "Si è verificato un errore sconosciuto.",
          life: 3000,
        });
      }
    }
  };

  const handlePrenotaClick = async (availability: Availability) => {
    if (!isLoggedIn) {
      dispatch(setAppointment(availability));
      setActiveIndex(2);
    } else {
      try {
        const [day, month, year] = availability.date.split("/");
        const formattedDate = `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
        const birthDate = new Date(user.patientInfo.birthDate);
        const formattedBirthDate = `${birthDate.getDate().toString().padStart(2, "0")}/${(birthDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}/${birthDate.getFullYear()}`;

        const convertTo24HourFormat = (time: string) => {
          const [timePart, modifier] = time.split(" ");
          let [hours, minutes] = timePart.split(":").map(Number);

          if (modifier === "PM" && hours < 12) {
            hours += 12;
          }
          if (modifier === "AM" && hours === 12) {
            hours = 0;
          }

          return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
        };

        const startTime = convertTo24HourFormat(availability.startTime);
        const endTime = convertTo24HourFormat(availability.endTime);

        const appointmentData = {
          userIdCode: user.codiceFiscale,
          startTime: startTime,
          endTime: endTime,
          appointmentDate: formattedDate,
          resourceId: availability.resourceId,
          activityId: availability.activityId,
          insuranceId: insurance,
          locationId: location,
          firstName: user.patientInfo.firstName,
          secondName: user.patientInfo.lastName,
          landlinePhone: user.patientInfo.telephone || "",
          mobilePhone: user.patientInfo.mobile || "",
          eMail: user.patientInfo.email,
          birthDate: formattedBirthDate,
        };

        const response = await fetch(url_addAppointment, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(appointmentData),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (data.result === "KO") {
          throw new Error(data.msg);
        }

        toast.current?.show({
          severity: "success",
          summary: "Prenotazione effettuata",
          detail: "La prenotazione è stata effettuata con successo.",
          life: 3000,
        });

        setActiveIndex(1);
      } catch (error) {
        if (error instanceof Error) {
          console.error("Fetch error:", error.message);
          toast.current?.show({
            severity: "error",
            summary: "Errore",
            detail: `Si è verificato un errore: ${error.message}`,
            life: 3000,
          });
        } else {
          console.error("Unexpected error:", error);
          toast.current?.show({
            severity: "error",
            summary: "Errore",
            detail: "Si è verificato un errore sconosciuto.",
            life: 3000,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (selectPrestazione) {
      const fetchResources = async () => {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 5000);

        try {
          const response = await fetch(`${url_getResources}?activity_lid=${selectPrestazione.id}`, {
            method: "GET",
            headers: { Authorization: `Bearer ${token}` },
            signal: controller.signal,
          });

          clearTimeout(timeoutId);

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data: { result: string; msg: string; return: Resource[] } = await response.json();

          const transformedData = data.return.map(resource => ({
            label: `${resource.name} ${resource.surname}`,
            value: resource.id,
          }));

          setMedici(transformedData);
        } catch (error) {
          if (error instanceof Error) {
            if (error.name === "AbortError") {
              console.error("Abort error");
            } else {
              console.error("Fetch error:", error.message);
            }
          } else {
            console.error("Unexpected error:", error);
          }
        }
      };

      fetchResources();
    }
  }, [selectPrestazione]);

  return (
    <div className="prenotazioni-div p-grid p-fluid">
      <Toast ref={toast} />
      <h2>Prenota un appuntamento</h2>
	  <p>Per prenotare prestazioni con le assicurazioni si prega di contattare telefonicamente il poliambulatorio</p>
      {availabilities.length === 0 ? (
        <>
          <div className="p-col-12 p-md-6 p-lg-4">
            <Dropdown
              value={selectPrestazione}
              onChange={(e: DropdownChangeEvent) => setSelectPrestazione(e.value)}
              options={groupedActivities}
              optionLabel="name"
              optionGroupLabel="label"
              optionGroupChildren="items"
              optionGroupTemplate={groupedItemTemplate}
              filter
              filterBy="name"
              filterMatchMode="contains"
              className="w-full dropdown"
              placeholder="Cerca Prestazioni"
              required
              loading={loading}
            />
          </div>
          {selectPrestazione != null && (
            <>
              <div className="p-col-12 p-md-6 p-lg-4 switch-prenotazione">
                <label htmlFor="switch">Non ho preferenze nella scelta del medico</label>
                <InputSwitch
                  checked={checked}
                  onChange={(e: InputSwitchChangeEvent) => {
                    setChecked(e.value);
                    setMedDis(true);
                    if (!e.value) {
                      setMedDis(false);
                    }
                  }}
                />
              </div>
              <div className="p-col-12 p-md-6 p-lg-4 seleziona-medico">
                <Dropdown
                  value={selectMedico}
                  onChange={(e: DropdownChangeEvent) => setSelectMedico(e.value)}
                  options={medici}
                  optionLabel="label"
                  filter
                  filterBy="label"
                  filterMatchMode="contains"
                  placeholder="Seleziona un medico"
                  className="w-full dropdown"
                  required
                  disabled={medDis}
                />
              </div>
            </>
          )}
          {(selectMedico != null || medDis) && (
            <>
              <div className="p-col-12 p-md-6 p-lg-4 mb-2">
                <label htmlFor="startTime" className="font-bold block mb-2">
                  A partire dalle ore:
                </label>
                <Dropdown
                  value={startTime}
                  options={timeOptions}
                  onChange={e => setStartTime(e.value)}
                  placeholder="opzionale"
                  className="w-full dropdown"
                />
              </div>
              <div className="p-col-12 p-md-6 p-lg-4 mb-2">
                <label htmlFor="endTime" className="font-bold block mb-2">
                  Entro le ore:
                </label>
                <Dropdown
                  value={endTime}
                  options={timeOptions}
                  onChange={e => setEndTime(e.value)}
                  placeholder="opzionale"
                  className="w-full dropdown"
                />
              </div>
              <div className="flex-auto">
                <label htmlFor="buttondisplay" className="font-bold block mb-2">
                  A partire dal:
                </label>
                <Calendar
                  id="buttondisplay"
                  locale="it"
                  className="dropdown"
                  value={date}
                  onChange={e => setDate(e.value)}
                  showIcon
                  dateFormat="dd/mm/yy"
                  minDate={today}
                  placeholder="opzionale"
                />
              </div>
              <div className="card flex flex-wrap justify-content-center gap-3">
                <Button className="submit-btn" label="INVIA" icon="pi pi-check" onClick={handleSubmit} />
              </div>
            </>
          )}
        </>
      ) : (
        <Accordion>
          {Object.entries(groupByDate(availabilities)).map(([date, dailyAvailabilities]) => (
            <AccordionTab key={date} header={date}>
              {dailyAvailabilities.map(availability => (
                <div key={availability.id} className="p-col-12 p-md-6 p-lg-4">
                  <div className="card">
                    <h3>{selectPrestazione?.name}</h3>
                    <p>
                      <i className="pi pi-calendar"></i> {availability.date} - {availability.startTime}
                    </p>
                    <p>
                      <i className="pi pi-user"></i> Dott.{" "}
                      {medici.find(m => m.value === availability.resourceId)?.label}
                    </p>
                    <p>
                      <i className="pi pi-euro"></i>
                      {availability.price}
                    </p>
                    <Button
                      label="Prenota"
                      className="p-button"
                      style={{ borderRadius: "10px" }}
                      onClick={() => openDialog(availability)}
                    />
                  </div>
                </div>
              ))}
            </AccordionTab>
          ))}
        </Accordion>
      )}
      <Dialog
        header="Conferma appuntamento"
        visible={dialogVisible}
        className="responsive-dialog"
        baseZIndex={1000}
        footer={
          <div>
            <Button
              label="Annulla"
              style={{ borderRadius: "10px" }}
              icon="pi pi-times"
              onClick={() => setDialogVisible(false)}
            />
            <Button label="Conferma" style={{ borderRadius: "10px" }} icon="pi pi-check" onClick={confirmAppointment} />
          </div>
        }
        onHide={() => setDialogVisible(false)}
      >
        <div>
          <h3>{selectPrestazione?.name}</h3>
          <p>
            <i className="pi pi-calendar"></i> {selectedAppointment?.date} - {selectedAppointment?.startTime}
          </p>
          <p>
            <i className="pi pi-user"></i> Dott. {medici.find(m => m.value === selectedAppointment?.resourceId)?.label}
          </p>
          <p>
            <i className="pi pi-euro"></i> {selectedAppointment?.price}
          </p>
        </div>
      </Dialog>
    </div>
  );
};

export default Prenotazioni;
